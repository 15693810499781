<template>
  <section
    class="background-cta"
    :class="{ 'background-cta--centered': centered }"
  >
    <div class="background-cta__block">
      <h3 class="background-cta__title">
        <slot name="title" />
      </h3>
      <img class="background-cta__image" :src="imageUrl" alt="homebourse" />
      <hb-btn-wrap>
        <slot name="buttons" />
      </hb-btn-wrap>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import HbBtnWrap from '~/components/base/utils/HbBtnWrap.vue'

export default defineComponent({
  name: 'BackgroundCta',
  components: {
    HbBtnWrap,
  },
  props: {
    imageUrl: { type: String, default: null },
    centered: { type: Boolean, default: false },
  },
})
</script>

<style lang="scss">
.background-cta {
  $prefix: &;

  position: relative;
  padding: 60px 0;

  &__block {
    padding: 60px 50px 70px 50px;
    border-radius: 10px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    z-index: -1;
  }

  &__title {
    margin-bottom: 50px;
    font-size: 50px;
    line-height: 56px;
    color: #fff;
  }

  &--centered {
    #{$prefix}__block {
      max-width: 722px;
      text-align: center;
      margin: 0 auto;
      padding: 0;
    }

    .button-wrapper {
      margin: 0 auto;
      justify-content: center;
    }
  }

  @include tablet {
    padding: 0;

    .container {
      padding-right: 0;
      padding-left: 0;
    }

    &__block {
      padding: 50px 30px 40px !important;
      border-radius: 10px;
    }

    &__image {
      border-radius: 10px;
    }

    &__title {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 35px;

      br {
        display: none;
      }
    }
  }
}
</style>
