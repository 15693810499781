<template>
  <hb-dialog class="video-dialog-player" size="lg" no-background>
    <div class="video-player">
      <video ref="video" controls autoplay preload="auto" playsinline>
        <source :src="videoUrl" type="video/mp4" />
      </video>
    </div>
    <hb-dialog-close size="sm" @click="dialog.close(afterClose)" />
  </hb-dialog>
</template>

<script lang="ts">
import HbDialog from '~/components/base/dialog/HbDialog.vue'
import { DialogMixin } from '~/mixins'
import { useDialog } from '~/composables'
import { HOW_TO_WORK_VIDEO } from '~/constants'
import HbDialogClose from '~/components/base/dialog/HbDialogClose.vue'

export default {
  name: 'VideoDialogPlayer',
  components: {
    HbDialogClose,
    HbDialog,
  },
  mixins: [DialogMixin],
  props: {
    videoUrl: {
      type: String,
      default: HOW_TO_WORK_VIDEO,
    },
  },
  setup(props) {
    const dialog = useDialog(props)

    return {
      dialog,
    }
  },
}
</script>

<style lang="scss">
.video-player {
  video {
    width: 100%;
    height: 100%;
  }
}

.video-dialog-player {
  .hb-dialog__close {
    right: 0;
    top: 0;
    z-index: 5;
  }
}
</style>
