<template>
  <Splide
    :has-track="false"
    :options="cityCarouselSettings"
    class="home-carousel city-carousel"
  >
    <div class="custom-wrapper">
      <SplideTrack>
        <SplideSlide
          v-for="(city, index) in cities"
          :key="index"
          :index="index"
        >
          <city-card :city="city" @click="emitClickOnItem(city)" />
        </SplideSlide>
      </SplideTrack>

      <ul class="splide__pagination"></ul>

      <div class="home-carousel__footer">
        <slot name="footer-right"></slot>
      </div>
    </div>
  </Splide>
</template>

<script lang="ts">
import { useModel } from 'wue'
import { computed } from 'vue'
import type { Options as SplideOptions } from '@splidejs/vue-splide'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide'
import { CarouselMixin } from '~/mixins'
import type { City } from '~/types'
import CityCard from '~/modules/home/components/CityCard.vue'

export default {
  name: 'CityCarousel',
  components: {
    CityCard,
    Splide,
    SplideSlide,
    SplideTrack,
  },
  mixins: [CarouselMixin],
  props: {
    cities: { type: Array as () => City[], default: () => [] },
  },
  emits: ['click-on-item'],
  setup(props, ctx) {
    const modelCtx = useModel<number>(props, ctx)
    const cityCarouselSettings = computed<SplideOptions>(() => ({
      type: 'slide',
      perPage: 5,
      gap: '15px',
      arrows: false,
      drag: false,
      breakpoints: {
        1150: {
          perPage: 4,
        },
        990: {
          perPage: 3,
        },
        767: {
          destroy: true,
        },
      },
    }))
    const emitClickOnItem = (city: City) => ctx.emit('click-on-item', city)

    return {
      cityCarouselSettings,
      ...modelCtx,
      emitClickOnItem,
    }
  },
}
</script>

<style lang="scss">
.city-carousel {
  @media (min-width: 768px) {
    &.splide.is-initialized:not(.is-active) .splide__list {
      display: flex;

      .splide__slide {
        width: calc(((100% + 15px) / 5) - 15px);
        margin-right: 15px;

        @media (max-width: 1150px) {
          width: calc(((100% + 15px) / 4) - 15px);
        }

        @media (max-width: 990px) {
          width: calc(((100% + 15px) / 3) - 15px);
        }
      }
    }
  }

  @include mobile {
    .splide__list {
      flex-direction: column !important;
      gap: 10px;

      .splide__slide {
        width: 100%;
      }
    }
  }
}
.home-carousel {
  @include tablet {
    margin: 0;
  }

  &__footer {
    @include tablet {
      margin-top: 40px;
      padding: 0 30px;
      display: flex;
      justify-content: center;
    }
  }

  .splide {
    &__pagination {
      max-width: var(--hb-container-max-width);
      padding: 0 40px;
      margin: 70px auto 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;

      @include tablet {
        display: none;
      }

      &__page {
        margin: 0;
        padding: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--hb-gray2);
        transition: background-color 0.5s ease;
        border: 0;
        cursor: pointer;

        &::after {
          content: unset;
        }

        &.is-active {
          background-color: var(--hb-blue1);
        }
      }
    }

    @include mobile {
      &__list {
        display: flex !important;
        flex-direction: row;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        padding: 0 15px !important;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__slide {
        width: 320px;
        margin: 0 7px;
        scroll-snap-align: center;
      }
    }
  }
}
</style>
