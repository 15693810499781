<template>
  <div style="display: contents">
    <main-hero />
    <!--<homes-by-city />-->
    <featured-projects />
    <!-- <homes-by-city show-cities /> -->
    <div class="how-does-it-work">
      <div class="container">
        <p v-html="$t('index.our_goal')"></p>
        <about-cards :no-border="true"></about-cards>
      </div>
      <div class="how-does-it-work__image">
        <!-- <div class="how-does-it-work__content" @click="openVideoDialog()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="88.965"
            height="88.965"
            viewBox="0 0 88.965 88.965"
          >
            <g
              id="Group_6516"
              data-name="Group 6516"
              transform="translate(-81.5 -2876.5)"
            >
              <g
                id="Group_6514"
                data-name="Group 6514"
                transform="translate(85 2880)"
              >
                <path
                  id="Path_891"
                  class="icon-bg"
                  data-name="Path 891"
                  d="M84.965,43.982A40.982,40.982,0,1,1,43.982,3,40.982,40.982,0,0,1,84.965,43.982Z"
                  transform="translate(-3 -3)"
                  fill="none"
                  stroke="#ff4176"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="7"
                />
                <path
                  id="Path_892"
                  data-name="Path 892"
                  d="M15,12,38.217,27.478,15,42.956Z"
                  transform="translate(18.245 13.505)"
                  fill="none"
                  stroke="#2b2051"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="7"
                />
              </g>
            </g>
          </svg>
          <div class="how-does-it-work__title">
            {{ $t("about.see_how_it_works") }}
          </div>
        </div> -->
        <img
          src="/images/how-does-it-work-bg.webp"
          :alt="$t('about.how_does_it_work')"
        />
      </div>
    </div>
    <!--    <div class="home-about">-->
    <!--      <div class="container">-->
    <!--        <div class="store-exp">-->
    <!--          <h1 class="store-exp__title">Online store experience</h1>-->
    <!--          <p class="store-exp__text">-->
    <!--            Homebourse brings you the true online store experience of buying-->
    <!--            <br />-->
    <!--            real estate online through a simple, automated process.-->
    <!--          </p>-->
    <!--        </div>-->
    <!--        <div class="buy-about">-->
    <!--          <div class="buy-about__item">-->
    <!--            <img src="/images/buy-1.svg" alt="" />-->
    <!--            <div>-->
    <!--              <h1 class="buy-about__title">Online real estate trading</h1>-->
    <!--              <p class="buy-about__text">-->
    <!--                Buy it, keep it, sell it…. without ever being there.-->
    <!--              </p>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="buy-about__item">-->
    <!--            <img src="/images/buy-2.svg" alt="" />-->
    <!--            <div>-->
    <!--              <h1 class="buy-about__title">Make an offer online</h1>-->
    <!--              <p class="buy-about__text">-->
    <!--                Get a counter-offer. Don’t argue. Accept or Decline it.-->
    <!--              </p>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="buy-about__item">-->
    <!--            <img src="/images/buy-3.svg" alt="" />-->
    <!--            <div>-->
    <!--              <h1 class="buy-about__title">No more bidding war</h1>-->
    <!--              <p class="buy-about__text">-->
    <!--                Find the best offers. Watch the prices and be the first to-->
    <!--                click.-->
    <!--              </p>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="buy-about__item">-->
    <!--            <img src="/images/buy-4.svg" alt="" />-->
    <!--            <div>-->
    <!--              <h1 class="buy-about__title">Close online</h1>-->
    <!--              <p class="buy-about__text">-->
    <!--                Leave the paperwork, and close using your device.-->
    <!--              </p>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="container featured-in__container">
      <h1 class="featured-in__title">{{ $t("main.featured_in") }}</h1>
      <div class="featured-in__elements">
        <div
          class="featured-in__element"
          v-for="(featured, index) in featuredList"
          :key="index"
        >
          <img
            :src="featured"
            loading="lazy"
            alt="featured_element_image"
            class="featured-in__element-image"
          />
        </div>
      </div>
    </div>
    <div class="container main-container">
      <div class="main-row">
        <div class="main-col main-col--full">
          <background-cta
            :image-url="
              isMobile ? '/images/cta2-mobile.webp' : '/images/cta2.webp'
            "
            centered
          >
            <template #title>
              <span v-html="$t('main.buy_real_estate')"></span>
            </template>
            <template #buttons>
              <!-- <hb-btn to="/search" size="lg" type="button">
                <span>{{ $t('label.lets_buy') }}</span>
              </hb-btn> -->
              <!-- <div @click="openInProgressDialog"> -->
              <div>
                  <hb-btn size="lg" type="button">
                  <span>{{ $t("label.search") }}</span>
                </hb-btn>
              </div>
              <!-- <start-selling-btn size="lg" outline outline-inverse /> -->
              <!-- <start-selling-btn size="lg" outline outline-inverse :is-disabled="true" /> -->
            </template>
          </background-cta>
        </div>
        <!--<div class="main-col">-->
        <!--  <background-cta :image-url="'/images/cta.webp'">-->
        <!--    <template #title>-->
        <!--      <span v-html="$t('main.link_your_friends')"></span>-->
        <!--    </template>-->
        <!--    <template #buttons>-->
        <!--      <hb-btn-->
        <!--        :to="-->
        <!--          userStore.authenticated-->
        <!--            ? '/profile/link-friends'-->
        <!--            : '/link-friends'-->
        <!--        "-->
        <!--        :size="isMobile ? 'lg' : 'md'"-->
        <!--      >-->
        <!--        <nuxt-icon name="user-plus" filled></nuxt-icon>-->
        <!--        <span>{{ $t('label.link_friends') }}</span>-->
        <!--      </hb-btn>-->
        <!--    </template>-->
        <!--  </background-cta>-->
        <!--</div>-->
      </div>
    </div>

    <!--    <partners />-->
  </div>
</template>

<script lang="ts">
import { navigateTo, useRouter } from "#app";
import { isClient } from "@vueuse/core";
import { onMounted, watch } from "vue";
import MainHero from "~/components/MainHero.vue";
import HomesByCity from "~/modules/home/components/HomesByCity.vue";
import BackgroundCta from "~/components/BackgroundCta.vue";
import HbBtn from "~/components/base/utils/HbBtn.vue";
import StartSellingBtn from "~/modules/home/components/StartSellingBtn.vue";
import { useDM, useInviteCodeCookie, useViewTools } from "~/composables";
import AuthDialog from "~/modules/auth/components/dialogs/AuthDialog.vue";
import { useUserStore } from "~/stores";
import { useI18n, useSeoMeta } from "#imports";
import AboutCards from "~/components/AboutCards.vue";
import VideoDialogPlayer from "~/components/dialogs/VideoDialogPlayer.vue";
import VerifyAccountDialog from "~/components/dialogs/VerifyAccountDialog.vue";
import ChangesComingDialog from "~/modules/auth/components/dialogs/ChangesComingDialog.vue";
import FeaturedProjects from "~/modules/project/components/FeaturedProjects.vue";

export default {
  name: "index",
  components: {
    AboutCards,
    StartSellingBtn,
    HbBtn,
    BackgroundCta,
    HomesByCity,
    MainHero,
    FeaturedProjects,
  },
  setup() {
    const { t } = useI18n();

    useSeoMeta({
      title: t("seo.main.title"),
      description: t("seo.main.description"),
    });

    const router = useRouter();
    const { open: openDialog } = useDM();
    const userStore = useUserStore();
    const goToLinkFriends = () => {
      navigateTo({ path: "/invite-friends" });
    };
    const showLogin = () => {
      const query = router.currentRoute.value.query;
      if (query.continue && isClient) {
        if (query.action === "verify") {
          openDialog(VerifyAccountDialog, {
            message: t("verification.email_verification_start_selling_text"),
            afterClose() {
              router.replace({
                query: {},
              });
            },
          });
        } else if (!userStore.authenticated) {
          openDialog(AuthDialog, {
            afterClose(isSuccess?: boolean) {
              if (isSuccess) {
                router.replace({
                  path: query.continue as string,
                  query: null,
                });
              } else {
                router.replace({
                  query: {},
                });
              }
            },
          });
        }
      }
    };

    const featuredList = [
      "/images/sunmasthead.svg",
      "/images/vice-logo.svg",
      "/images/refresh-miami.png",
      "/images/techbullion-logo.png",
      "/images/yahoo-finance.png",
      "/images/group-6613.png",
    ];

    const openInProgressDialog = () => {
      useDM().open(ChangesComingDialog, {
        verticalCenter: true,
        closeByEsc: true,
        closeByOverlay: true,
      });
    };

    const openVideoDialog = () => {
      useDM().open(VideoDialogPlayer, {
        verticalCenter: true,
        closeByEsc: true,
        closeByOverlay: true,
      });
    };

    onMounted(() => {
      setTimeout(() => {
        const referralCode = useInviteCodeCookie().value;

        if (referralCode && isClient && !userStore.authenticated) {
          openDialog(AuthDialog, {
            initSignUp: true,
            referralCode,
          });
          useInviteCodeCookie().value = null;
        }

        showLogin();
      }, 0);
    });

    watch(
      () => router.currentRoute.value.query,
      () => showLogin(),
      { flush: "post" }
    );

    return {
      userStore,
      goToLinkFriends,
      ...useViewTools(),
      openVideoDialog,
      openInProgressDialog,
      featuredList,
    };
  },
};
</script>

<style lang="scss">
.main-row {
  display: flex;
  margin-right: -12px;
  margin-left: -12px;

  @include tablet {
    flex-direction: column;
    gap: 24px;
    margin: 0;
  }
}

.main-col {
  padding-right: 12px;
  padding-left: 12px;
  flex: 0 0 50%;

  @include tablet {
    flex: 0 0 100%;
    padding: 0;
  }

  &--full {
    flex: 0 0 100%;
  }
}

.main-container {
  @include desktop {
    padding: 0 20px;
  }
}

.buy-about {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-bottom: 105px;

  @include mobile {
    margin-top: 20px;
    margin-bottom: 70px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 40px;
    gap: 40px;
    border: 1px solid var(--hb-gray2);
    border-radius: 10px;
    flex: 0 0 calc(50% - 13px);

    @include mobile {
      flex-direction: column;
      text-align: center;
      gap: 20px;
      flex: 0 0 100%;
    }

    img {
      width: 65px;
    }
  }

  .buy-about__title {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 28px;
    font-weight: 700;
    color: var(--hb-blue1);

    @include mobile {
      font-size: 20px;
      line-height: 18px;
    }
  }

  .buy-about__text {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0;
  }
}

.store-exp {
  &__title {
    color: var(--hb-blue1);
    margin-bottom: 25px !important;

    span {
      color: var(--hb-primary);
    }
  }

  &__text {
    margin-bottom: 0 !important;
  }
}

.home-about {
  padding-top: 70px;

  @include mobile {
    padding-top: 20px;
  }

  .store-exp {
    margin-bottom: 55px;

    &__title {
      font-size: 30px;
      line-height: 40px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 25px !important;

      span {
        color: var(--hb-primary);
      }
    }

    &__text {
      font-size: 16px;
      line-height: 25px;
      text-align: center;
      margin-bottom: 0 !important;
    }
  }

  .buy-about {
    margin-bottom: 50px;
    gap: 20px;
    max-width: 885px;
    margin-left: auto;
    margin-right: auto;

    &__item {
      padding: 30px 15px 30px 25px;
      gap: 20px;

      @include mobile {
        padding: 30px 15px 30px 15px;
      }

      img {
        width: 50px;
      }
    }

    &__title {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 5px;
    }

    &__text {
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.how-does-it-work {
  //padding: 75px 0 0;
  padding: 75px 0 0;
  margin: 80px 0;
  background-color: var(--hb-gray2);

  p {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;

    @include mobile {
      font-size: 18px;
      line-height: 28px;
    }
  }

  &__image {
    position: relative;
    height: 0;
    padding-top: 45%;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 30%;
      background: transparent
        linear-gradient(180deg, #e1e6e8 0%, #e1e6e800 100%) 0% 0% no-repeat
        padding-box;
      z-index: 1;
    }

    @media (min-width: 1500px) {
      padding-top: 40%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 82px), -50%);
    text-transform: uppercase;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    gap: 30px;
    cursor: pointer;

    svg {
      width: 82px;
      height: 82px;

      path {
        transition: stroke 0.3s ease, fill 0.3s ease;
      }
    }

    &:hover {
      svg {
        path.icon-bg {
          stroke: #fff;
          fill: #fff;
        }
      }
    }

    @include mobile {
      top: -10%;
      transform: translate(-50%, -50%);
      gap: 20px;

      svg {
        max-width: 40px;
        max-height: 40px;
        min-width: 40px;
        min-height: 40px;
      }
    }
  }

  &__title {
    font-size: 26px;
    line-height: 26px;
    color: var(--hb-blue1);
    font-weight: 900;
    max-width: 145px;

    @include mobile {
      font-size: 22px;
      line-height: 22px;
      width: 140px;
    }
  }
}

.featured-in {
  &__container {
    padding-top: 25px;
  }

  &__title {
    font-size: 26px;
    letter-spacing: 0;
  }

  &__elements {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 34px 0 91px 0;

    @include mobile {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__element {
    width: 195px;
    height: 160px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    border-color: #c2cdd1;

    @include mobile {
      width: 100%;
    }
  }

  &__element-image {
    max-width: 144px;
  }
}
</style>
