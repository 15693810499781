<template>
  <div
    class="city-card"
    :class="{ 'city-card--coming-soon': city?.comingSoon }"
  >
    <img
      class="city-card__image"
      :src="city?.image"
      :alt="city?.name"
      loading="lazy"
      fetchpriority="low"
    />
    <div class="city-card__name">{{ city?.name }}</div>
  </div>
</template>

<script lang="ts">
import type { City } from '~/types'

export default {
  name: 'CityCard',
  components: {},
  props: {
    city: { type: Object as () => City, required: true },
  },
}
</script>

<style lang="scss">
.city-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 390px;
  position: relative;
  border-radius: 6px;
  padding: 40px 25px;
  background-color: #373737;
  transition: 0.3s all;
  cursor: pointer;
  overflow: hidden;

  &--coming-soon {
    cursor: default;
    pointer-events: none;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #c2cdd1;
    }

    &:after {
      content: 'Coming soon!';
      position: absolute;
      bottom: 26px;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-size: 15px;
      font-weight: 700;

      @include mobile {
        bottom: 18px;
        left: 30px;
        transform: translateX(0);
      }
    }

    .city-card__image {
      filter: grayscale(1);
      opacity: 0.2;
    }
  }

  &:hover {
    .city-card__image {
      transform: scale(1.075);
    }

    .city-card__name {
      color: var(--hb-primary);
    }
  }

  @media (min-width: 768px) {
    &:hover {
      box-shadow: 30px 20px 99px #1d323d4d;
    }
  }

  &__image {
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.65;
    transition: 2s all;
  }

  &__name {
    position: relative;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 34px;
    font-weight: 800;
    text-align: center;
    color: var(--hb-white);
    transition: 0.3s all;
  }

  @include mobile {
    height: 120px;
    padding: 30px;
    justify-content: flex-start;

    &__name {
      text-align: left;
    }
  }
}
</style>
