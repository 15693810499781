<template>
  <section class="homes-by-city">
    <div class="container">
      <div class="homes-by-city__top">
        <div :class="cityToggleClasses">
          {{
            showCities ? $t('home.hot_locations') : $t('home.featured_homes')
          }}
        </div>
      </div>
      <div>
        <hb-transition v-if="!showCities" :overflowed="isTablet">
          <div v-if="pending" class="static-container">
            <block-loader :min-height="blockMinHeight" />
          </div>
          <div v-else ref="contentEl">
            <div v-if="homes.length">
              <client-only>
                <home-carousel v-if="isTablet" :homes="homes" />
                <transition v-else name="fade">
                  <homes :key="activeDot" :homes="visibleHomes" :cols="cols" />
                </transition>

                <template #fallback>
                  <homes :homes="homes" :cols="cols" />
                </template>
              </client-only>
            </div>
            <div v-else class="static-container">
              <no-results :min-height="blockMinHeight" />
            </div>
          </div>
        </hb-transition>
        <client-only>
          <hb-transition v-if="showCities" :overflowed="isTablet">
            <city-carousel :cities="cities" @click-on-item="selectCity" />
            <template #fallback>
              <cities
                :cities="cities"
                :cols="cols"
                @click-on-item="selectCity"
              />
            </template>
          </hb-transition>
        </client-only>
        <div
          v-if="!showCities && !isTablet && dotNumber > 1 && !pending"
          class="homes-by-city__nav"
        >
          <carousel-pagination v-model="activeDot" :dot-number="dotNumber" />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import type { PropertyListItem, PropertySearch } from '@homebourse/api-client'
import {
  PropertySearchDirectionEnum,
  PropertySearchOrderByEnum,
} from '@homebourse/api-client'
import { computed, ref } from 'vue'
import { isClient, useWindowSize } from '@vueuse/core'
import { useAsyncData } from '#app'
import type { City } from '~/types'
import BlockLoader from '~/components/loaders/BlockLoader.vue'
import Cities from '~/modules/home/components/Cities.vue'
import Homes from '~/modules/home/components/Homes.vue'
import NoResults from '~/components/NoResults.vue'
import { CityData } from '~/static-data'
import { usePropertyStore } from '~/stores'
import { onAuthChange } from '~/modules/auth/composables/auth-change'
import { useViewTools } from '~/composables'
import HomeCarousel from '~/modules/home/components/carousels/HomeCarousel.vue'
import CityCarousel from '~/modules/home/components/carousels/CityCarousel.vue'
import CarouselPagination from '~/modules/home/components/carousels/CarouselPagination.vue'
import { goToSearchPage } from '~/utils'
import HbTransition from '~/components/base/utils/HbTransition.vue'

export default {
  name: 'HomesByCity',
  components: {
    HbTransition,
    CarouselPagination,
    CityCarousel,
    HomeCarousel,
    NoResults,
    Homes,
    Cities,
    BlockLoader,
  },
  props: {
    showCities: { type: Boolean, default: false },
  },
  setup(props) {
    const contentEl = ref<HTMLElement>(null)
    const blockMinHeight = ref<number>(420)
    const { width: windowWidth } = useWindowSize()
    const cols = computed(() => {
      if (!isClient) {
        return 1
      }

      if (props.showCities) {
        switch (true) {
          case windowWidth.value >= 1150:
            return 5

          case windowWidth.value >= 1050:
            return 4

          case windowWidth.value >= 750:
            return 3

          case windowWidth.value >= 620:
            return 2

          default:
            return 1
        }
      } else {
        switch (true) {
          case windowWidth.value >= 1100:
            return 4

          case windowWidth.value >= 940:
            return 3

          case windowWidth.value >= 620:
            return 2

          default:
            return 1
        }
      }
    })
    const activeDot = ref<number>(0)
    const cities = ref(CityData)
    const propertyStore = usePropertyStore()
    const {
      execute: searchHomes,
      data: homesData,
      pending,
    } = useAsyncData<PropertySearch>(
      () => {
        return propertyStore.api.propertySearch({
          perPage: 12,
          highlighted: true,
          orderBy: PropertySearchOrderByEnum.ListedAt,
          direction: PropertySearchDirectionEnum.Desc,
        })
      },
      { immediate: !props.showCities }
    )
    const homes = computed<PropertyListItem[]>(() => {
      return homesData.value?.properties?.data ?? []
    })
    const dotNumber = computed(() => {
      const itemCount = homes.value.length
      const count = Math.ceil(itemCount / cols.value)

      return count
    })
    const cityToggleClasses = computed(() => ({
      'homes-by-city__select': true,
      'homes-by-city__select--open': props.showCities,
      'homes-by-city__select--disabled': pending.value,
    }))
    const visibleHomes = computed<PropertyListItem[]>(() => {
      return homes.value.slice(
        activeDot.value * cols.value,
        (activeDot.value + 1) * cols.value
      )
    })
    const selectCity = (city: City) => {
      goToSearchPage(city.regionId, city.slug)
    }

    onAuthChange(() => searchHomes())

    return {
      contentEl,
      blockMinHeight,
      cols,
      cities,
      homes,
      pending,
      cityToggleClasses,
      activeDot,
      dotNumber,
      visibleHomes,
      selectCity,
      ...useViewTools(),
    }
  },
}
</script>

<style scoped lang="scss">
.homes-by-city {
  --hb-home-carousel-slide-padding-x: 11.5px;
  padding: 60px 0 30px;

  @include tablet {
    --hb-home-carousel-slide-padding-x: 7.5px;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    @include tablet {
      max-width: var(--hb-container-max-width);
      padding: 0 var(--hb-container-padding-x);
      margin: 0 auto 35px;

      .hb-btn {
        display: none;
      }
    }
  }

  &__select {
    font-size: 30px;
    line-height: 2;
    font-weight: 700;

    br {
      display: none;
    }

    a {
      font-weight: 800;
      text-decoration: none;
      color: inherit;
      transition: 0.3s all;

      &:hover {
        color: var(--hb-primary);

        .nuxt-icon {
          color: var(--hb-primary);
        }
      }
    }

    .nuxt-icon {
      font-size: 11px;
      position: relative;
      top: -6px;
      transition: transform 0.3s ease, color 0.3s;
      margin-left: 15px;
    }

    &--open {
      .nuxt-icon {
        transform: rotate(-180deg);
      }
    }

    &--disabled {
      pointer-events: none;
      user-focus: none;
      user-select: none;
      touch-action: none;
      cursor: default;
    }
  }

  &__nav {
    display: flex;
    justify-content: center;
    padding-top: 40px;

    @include mobile {
      max-width: var(--hb-container-max-width);
      padding: 0 var(--hb-container-padding-x);
      margin: 0 auto 35px;
    }

    .hb-btn {
      display: none;
    }
  }

  .container {
    @include container;

    @include desktop {
      padding: 0 40px;
    }

    @include tablet {
      max-width: 100%;
      padding: 0;
    }
  }

  @include tablet {
    padding: 45px 0 15px;

    &__nav {
      padding-top: 40px;
      justify-content: space-between;

      .hb-btn {
        display: inline-block;
      }
    }

    &__select {
      font-size: 26px;
      line-height: 34px;

      br {
        display: block;
      }
    }
  }
}

.static-container {
  @include tablet {
    padding: 0 20px;
  }
}

.view-all-btn {
  .nuxt-icon {
    font-size: 23px;
    margin-left: 32px;
  }
}
</style>
