<template>
  <div class="hb-main-hero">
    <div class="hb-main-hero__bg">
      <img src="/images/new-index-hero.png" alt="homebourse" />
    </div>
    <div class="container">
      <h2 class="hb-main-hero__text" :class="{ visible: loaded }" v-html="$t('main.hero_text')">
      </h2>
      <h1
        class="hb-main-hero__title"
        :class="{ visible: loaded }"
        v-html="$t('main.hero_title')"
      ></h1>
      <hb-box class="hb-main-hero__search-box" :class="{ visible: loaded }">
        <location-search />
      </hb-box>
      <!-- <hb-btn
        class="hb-main-hero__cta"
        theme="secondary"
        rounded
        :class="{ visible: loaded }"
        @click="openVideoDialog()"
      >
        <nuxt-icon name="play-circle" filled></nuxt-icon>
        <span>{{ $t('label.how_it_works') }}</span>
      </hb-btn> -->
    </div>
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import { onMounted, useDM } from "#imports";
import HbBox from "~/components/base/HbBox.vue";
import LocationSearch from "~/components/form/LocationSearch.vue";
import VideoDialogPlayer from "~/components/dialogs/VideoDialogPlayer.vue";
import HbBtn from "~/components/base/utils/HbBtn.vue";

export default {
  name: "MainHero",
  components: {
    HbBtn,
    LocationSearch,
    HbBox,
  },
  setup() {
    const loaded = ref(false);

    onMounted(() => {
      setTimeout(() => {
        loaded.value = true;
      }, 0);
    });

    const openVideoDialog = () => {
      useDM().open(VideoDialogPlayer, {
        verticalCenter: true,
        closeByEsc: true,
        closeByOverlay: true,
      });
    };

    return {
      loaded,
      openVideoDialog,
    };
  },
};
</script>

<style lang="scss">
.hb-main-hero {
  position: relative;
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 46px;
    line-height: 52px;
    font-weight: 700;
    letter-spacing: -0.52px;

    span {
      color: var(--hb-primary);
    }

    opacity: 0;
    transition: 0.5s all;

    &.visible {
      opacity: 1;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 700;
    line-height: 2;

    & + .hb-main-hero__title {
      margin-top: 26px;
    }

    opacity: 0;
    transition: 0.5s all;

    &.visible {
      opacity: 1;
    }
  }

  &__search-box {
    margin-top: 33px;
    max-width: 660px;
    width: 100%;
    text-align: left;

    opacity: 0;
    transition: 0.8s all;
    transition-delay: 0.2s;
    transform: translateY(10px);

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__cta {
    margin-top: 67px;

    .nuxt-icon {
      color: var(--hb-primary);
      transition: color 0.3s;
    }

    @include btn-hover {
      .nuxt-icon {
        color: #fff;
      }
    }

    opacity: 0;
    transition: 0.8s all;
    transition-delay: 0.5s;
    transform: translateY(10px);

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom center;

      @include tablet {
        object-position: 75% center;
      }
    }
  }

  @include mobile {
    height: 500px;

    &__text {
      font-size: 13px;
      line-height: 16px;
    }

    &__title {
      font-size: 30px;
      line-height: 38px;
    }

    &__search-box {
      gap: 0;

      .hb-autocomplete__input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-right: 0;
      }

      .hb-btn-icon {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: 0;
      }
    }

    &__cta {
      margin-top: 35px;
    }
  }
}
</style>
