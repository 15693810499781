<template>
  <div class="carousel-pagination">
    <a
      v-for="(number, index) in dotNumber"
      :key="index"
      href
      class="carousel-pagination__dot"
      :class="{
        'carousel-pagination__dot--active': isActiveDot(index),
      }"
      @click.prevent.stop="setActiveDot(index)"
    ></a>
  </div>
</template>

<script lang="ts">
import { ModelMixin, useModel } from 'wue'

export default {
  name: 'CarouselPagination',
  mixins: [ModelMixin],
  props: {
    dotNumber: { type: Number, default: 0 },
  },
  setup(props, ctx) {
    const { model } = useModel<number>(props, ctx)
    const isActiveDot = (index: number) => model.value === index
    const setActiveDot = (index: number) => (model.value = index)

    return {
      isActiveDot,
      setActiveDot,
    }
  },
}
</script>

<style scoped lang="scss">
.carousel-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  &__dot {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--hb-gray2);
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
    color: inherit;

    &:hover {
      background-color: var(--hb-gray3);
    }

    &--active {
      background-color: var(--hb-blue1) !important;
    }
  }

  @include mobile {
    display: none;
  }
}
</style>
