<template>
  <card-list class="homes-list" :cols="cols">
    <card-list-item v-for="home in homes" :key="home.slug" :cols="cols">
      <home-card :property="home" />
    </card-list-item>
  </card-list>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropertyListItem } from '@homebourse/api-client'
import HomeCard from '~/modules/home/components/HomeCard.vue'
import CardList from '~/components/CardList.vue'
import CardListItem from '~/components/CardListItem.vue'

export default defineComponent({
  name: 'Homes',
  components: {
    CardListItem,
    CardList,
    HomeCard,
  },
  props: {
    cols: { type: Number, default: 0 },
    homes: { type: Array as () => PropertyListItem[], default: () => [] },
  },
})
</script>

<style lang="scss">
.homes-list {
  @include mobile {
    @include container;
  }
}
</style>
