<template>
  <card-list :cols="cols">
    <card-list-item v-for="city in cities" :key="city.slug" :cols="cols">
      <city-card :city="city" @click="emitClickOnItem(city)" />
    </card-list-item>
  </card-list>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CityCard from '~/modules/home/components/CityCard.vue'
import CardList from '~/components/CardList.vue'
import CardListItem from '~/components/CardListItem.vue'
import type { City } from '~/types'

export default defineComponent({
  name: 'Cities',
  components: {
    CardListItem,
    CityCard,
    CardList,
  },
  props: {
    cols: { type: Number, default: 0 },
    cities: { type: Array as () => City[], default: () => [] },
  },
  emits: ['click-on-item'],
  setup(props, ctx) {
    const emitClickOnItem = (city: City) => ctx.emit('click-on-item', city)

    return {
      emitClickOnItem,
    }
  },
})
</script>

<style lang="scss"></style>
