<template>
  <Splide
    :has-track="false"
    :options="homeCarouselSettings"
    class="home-carousel"
  >
    <div class="custom-wrapper">
      <SplideTrack>
        <SplideSlide v-for="(home, index) in homes" :key="index" :index="index">
          <home-card :property="home" no-shadow block />
        </SplideSlide>
      </SplideTrack>

      <ul class="splide__pagination"></ul>

      <div class="home-carousel__footer">
        <slot name="footer-right"></slot>
      </div>
    </div>
  </Splide>
</template>

<script lang="ts">
import type { PropertyListItem } from '@homebourse/api-client'
import { useModel } from 'wue'
import { computed, ref } from 'vue'
import type { Options as SplideOptions } from '@splidejs/vue-splide'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide'
import { CarouselMixin } from '~/mixins'
import HomeCard from '~/modules/home/components/HomeCard.vue'

export default {
  name: 'HomeCarousel',
  components: {
    HomeCard,
    Splide,
    SplideSlide,
    SplideTrack,
  },
  mixins: [CarouselMixin],
  props: {
    homes: { type: Array as () => PropertyListItem[], default: () => [] },
  },
  setup(props, ctx) {
    const modelCtx = useModel(props, ctx)
    const carouselEl = ref<InstanceType<typeof Splide>>(null)
    const homeCarouselSettings = computed<SplideOptions>(() => ({
      type: 'slide',
      perPage: 4,
      gap: '15px',
      arrows: false,
      drag: false,
      breakpoints: {
        1150: {
          perPage: 3,
        },
        990: {
          perPage: 2,
        },
        768: {
          destroy: true,
        },
      },
    }))

    return {
      homeCarouselSettings,
      carouselEl,
      ...modelCtx,
    }
  },
}
</script>

<style lang="scss">
.home-carousel {
  @include tablet {
    margin: 0;
  }

  @media (min-width: 769px) {
    &.splide.is-initialized:not(.is-active) .splide__list {
      display: flex;

      .splide__slide {
        width: calc(((100% + 15px) / 4) - 15px);
        margin-right: 15px;

        @media (max-width: 1150px) {
          width: calc(((100% + 15px) / 3) - 15px);
        }

        @media (max-width: 990px) {
          width: calc(((100% + 15px) / 2) - 15px);
        }
      }
    }
  }

  &__footer {
    @include mobile {
      margin-top: 40px;
      padding: 0 30px;
      justify-content: space-between;
    }
  }

  .splide {
    &__pagination {
      max-width: var(--hb-container-max-width);
      padding: 0 40px;
      margin: 70px auto 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;

      @include tablet {
        display: none;
      }

      &__page {
        margin: 0;
        padding: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--hb-gray2);
        transition: background-color 0.5s ease;
        border: 0;
        cursor: pointer;

        &::after {
          content: unset;
        }

        &.is-active {
          background-color: var(--hb-blue1);
        }
      }
    }

    @include tablet {
      &__list {
        display: flex !important;
        flex-direction: row;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        padding: 0 15px !important;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__slide {
        width: 320px;
        margin: 0 7px;
        scroll-snap-align: center;
      }
    }
  }
}
</style>
